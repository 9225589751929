@include keyframes(bounceIn) {
  0% {
    opacity: 0;
    @include transform(scale(.3));
  }
  50% {
    opacity: 1;
    @include transform(scale(1.05));
  }
  100% {
	@include transform(scale(1));
  }

}

@mixin bounceIn($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(bounceIn);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
