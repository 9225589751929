.page-navbar {
  margin-bottom: 20px;
  .social-icons {
    font-size: 40px;
    a {
      display: inline-block;
      padding: 5px;
      color: #101010;
      transition: 0.5s linear;

      &:hover {
        text-decoration: none;
        transform: rotate(360deg) scale(1.4);
      }
    }

  }
  .navbar-default {
    border-top: 0;
    border-bottom: 1px solid #b7b7b7;
    text-transform: uppercase;
    font-family: $headings-font-family;
    padding-bottom: 10px;
    img {
      display: inline-block;
    }
    .logo {
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .navbar-nav {
      li a, li span {
        display: block;
        font-size: 18px;
        color: #000;
        padding: 5px 15px;
        margin: 3px;
        background-color: #dcdcdc;
        font-weight: 400;
        transition: 0.2s linear;

        &:hover {
          color: #fff;
          background-color: #555;

        }

        &.phone {
          background-color: #fff;
          display: inline-block;
          font-size: 28px;
          font-style: italic;
          color: #101010;
          line-height: 1;
        }
        &.secure {
          background-color: #fff;
        }
        &.order-desktop {
          color: #fff;
          background: #ff0000;
          animation: inset-shine 5s ease-in infinite;

          &:hover{
            background: #dd0000;
          }
        }

      }
    }

    button, .order-mobile {
      background-color: #dcdcdc;
      font-weight: 400;
      border-radius: 0;
      font-size: 22px;
      padding: 5px 15px;
      margin: 3px;
      color: #101010;
      float: left;
      border: none;
      text-decoration: none;
    }
    .mobile-buttons {
      width: 100%;
      position: relative;

      button {
        width: 65%;
      }
      a {
        width: 30%;
        color: #fff;
        background: red;
        animation: inset-shine 5s ease-in infinite;

      }
    }
  }
  @include xs-query {
    text-align: center;
    .norton {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }
}
#wrapper{
  position: relative;
  padding-bottom: 250px;
  @include sm-max-query{
    padding-bottom: 0;
  }
}
#footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #101010;
  padding: 30px;
  img {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .main, .other, .social-icons {
    display: block;
    margin-bottom: 0;
    width: 100%;
    float: right;
    text-align: right;

    li {
      display: inline-block;

      a {
        display: block;
        padding: 5px 15px;

      }
    }
  }
  .main li a {
    font-family: $headings-font-family;
    font-size: 18px;
    padding: 5px 8px 10px;
    color: #cdcccc;
  }
  .other a, p {
    font-size: 14px;
    color: #5e5e5e;

  }
  .social-icons a {
    font-size: 40px;
    color: #6f6f6f;
  }
  @include sm-max-query{
    position: relative;
    padding: 30px 0;
    text-align: center;
    .main, .other, .social-icons{
      float: none;
      text-align: center;
      display: inline-block;
    }
    ul{
      padding: 0;
    }
  }
}


