#products {
  .product {
    border: 8px solid #000;
    padding-top: 30px;
    margin-bottom: 40px;
    .close {
      background-color: #fff;
      border-radius: 50px;
      width: 30px;
      height: 30px;
      margin-bottom: -15px;
      margin-right: -15px;
    }
    .content-title {
      background-color: #ffd800;
      padding: 12px 15px;
      font-size: 33px;
      font-weight: 500;
      font-style: italic;
      letter-spacing: -1px;
      line-height: 1.2;

      .fa {
        font-size: 18px;
        vertical-align: top;
        letter-spacing: 8px;
      }
    }
    .content-text {
      padding: 10px 50px 0 30px;
      font-size: 18px;
      line-height: 1.6;

    }
    .logo {
      padding-bottom: 10px;
      margin-top: 20px;
    }

  }
  .h3 {
    font-size: 30px;
    font-family: $font-family-base;
  }

  .box {
    display: inline-block;
    background-color: #dbdbdb;
    font-family: $headings-font-family;
    font-size: 24px;
    padding: 0 10px;
    margin-left: 10px;
    margin-bottom: 10px;

  }
}

.products-table .table {
  th, td {
    border-color: #585858;
    @include fluid-type(padding-left, $screen-xs, $screen-md-max, 5px, 15px);
    @include fluid-type(padding-right, $screen-xs, $screen-md-max, 5px, 15px);

    &:nth-child(1) {
      color: #fff;
    }
    &:nth-child(2) {
      background: #383838;

    }
    &:nth-child(3) {
      background: #202020;

    }
    &:nth-child(4) {
      background: #000000;
    }
  }

  th {
    vertical-align: middle;
    &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      text-align: center;
      font-family: 'Sui Generis Rg', $headings-font-family;
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 10px, 20px);
      padding: 10px 0;
      color: #ffd900;
      @include fluid-type(letter-spacing, $screen-xs, $screen-md-max, 0px, 10px);
    }

  }
  td {
    text-align: center;
    color: #fff;
  }
  thead {
    th:nth-child(1) {
      background-color: #000000;
      color: #fff;
      font-family: $headings-font-family;
      line-height: 1;
      b{
        @include fluid-type(font-size, $screen-xs, $screen-md-max, 20px, 57px);
        font-weight: 600;
        font-style: italic;
      }
      .yellow{
        @include fluid-type(font-size, $screen-xs, $screen-md-max, 18px, 28px);
        color: #ffd900;
        font-weight: 400;
      }
    }
  }
  tbody {
    th {
      background-color: #777777;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -1px;
      .yel{
        color: #ffd900;
      }
    }
  }
  tfoot {
    border-top: 1px solid #585858;

    th {

      background-color: #777777;
      color: #fff;
      font-size: 16px;
      border-top: none;
      vertical-align: middle;
    }
    td {
      border-top: none;
      vertical-align: middle;
      font-family: $headings-font-family;
    }

    .btn.btn-red {
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 16px, 28px);
      border-radius: 50px;
      @include sm-max-query{
        border-radius: 0;
        width: 100%;
        padding: 10px 5px;
        margin-right: -5px;
        margin-left: -5px;
      }
    }

    .current-price{
      font-style: italic;
      font-weight: 600;
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 22px, 50px);

      td{
        line-height: 1;
      }

    }
  }

}

.products-table {
  ul {
    list-style: none;
    li {
      font-size: 16px;
      font-style: italic;
      color: #797979;
      margin-bottom: 20px;
      position: relative;

      &:before {
        position: absolute;
        text-align: right;
        width: 20px;
        left: -1.7em;
      }
      &:nth-child(1) {
        &:before {
          content: "*";
        }
      }
      &:nth-child(2) {
        &:before {
          content: "**";
        }
      }
      &:nth-child(3) {
        &:before {
          content: "***";
        }
      }
    }
  }

}

