.pop-video {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  opacity: 0;
  transition: opacity .5s;

  &.show {
    opacity: 1;

    .video-content {
      opacity: 1;
      top: 25px;
    }
  }

  .video-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*background: rgba(0, 0, 0, .90);*/
  }

  .video-content {
    position: absolute;
    top: 0;
    right: 25px;
    opacity: 0;
    width: 100%;
    max-width: 400px;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 10px;
    background: #101010;
    transition: all .5s;
    transition-delay: .5s;
  }

  .video-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 42px;
    height: 42px;
    border: 2px solid #fff;
    border-radius: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    line-height: 1;
    cursor: pointer;

    i {
      position: relative;
      top: -1px;
    }
  }
  video {
    max-width: 100%;
  }
  .play, .pause {
    color: #fff;
    font-size: 60px;
    line-height: 60px;
    position: absolute;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    z-index: 1000;
    opacity: 0;
    animation: pop_video_buttons 0.5s ease-out;

  }
  .play:before {
    content: $fa-var-play;
    font-family: FontAwesome; // or \f04b
  }
  .pause:before {
    content: $fa-var-pause;
    font-family: FontAwesome;
  }
}

@keyframes pop_video_buttons {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0;
  }

}

@include sm-max-query {
  .pop-video .video-content {
    right: auto;
    top: 50%;
    left: 50%;
    transform: translateY(0) translateX(-50%);

    &.show .video-content {
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}
