$secFont: "Rift Demi", $headings-font-family;
html, body {
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  font-weight: 100;
  min-height: 100%;

  &.no-scroll {
    overflow: hidden;
  }
}

.social-icons {
  font-family: 'SocialCircles';
}

.fadeIn {
  @include fadeInUp($delay: 2s);
}

.y-bg {
  background-color: #ffd800;
  color: #101010;
}

.bl-bg {
  background-color: #199ed9;
  color: #fff;
}

.bg-grey {
  background: url("../img/bg-grey.jpg") top center no-repeat;
  background-size: cover;
  padding: 40px 0;
}

.bg-blue {
  background: url("../img/bg-blue.jpg") center center no-repeat;
  background-size: cover;
  padding: 40px 0;
  color: #fff;
}

.header-lines {
  border-top: 3px solid #c0c0c0;
  border-bottom: 3px solid #c0c0c0;
  .text-center & {
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
  }
}
.select-package {
  .package {
    text-align: center;
    box-shadow: 0 0 0 4px #000;
    margin-bottom: 40px;

  }
  .top {
    font-family: 'Sui Generis Rg', $headings-font-family;
    background-color: black;
    @include fluid-type(font-size, $screen-xs, $screen-md-max, 22px, 32px);
    padding: 10px;
    color: #ffd900;
    @include fluid-type( letter-spacing, $screen-xs, $screen-md-max, 3px, 10px);
  }
  .content {
    font-size: 16px;
    padding: 25px 15px;

  }
  .price{
    font-family: $headings-font-family;
    color: #00b3ee;
    @include fluid-type(font-size, $screen-xs, $screen-md-max, 30px, 54px);
    font-style: italic;
    font-weight: 600;
  }
  .yellow {
    background: #ffd900;
    @include fluid-type(font-size, $screen-xs, $screen-md-max, 22px, 28px);
    font-family: $headings-font-family;
    font-weight: 600;
    font-style: italic;
    padding: 10px;
    line-height: 1;
    text-align: center;

  }
  .items {
    color: #fff;
    font-family: $secFont;
    font-weight: 600;
    font-size: 20px;

    @include md-min-query {
      &.collapse {
        display: block;
        height: 100% !important;
      }
    }

    div {
      padding: 10px;
      &:nth-child(odd) {
        background: #000000;
      }
      &:nth-child(even) {
        background: #3d3d3d;
      }
    }
  }
  .select {

    background: #e20000;
    @include background-image(linear-gradient('to bottom', #e20000, #9a0000));
    font-family: $secFont;
    @include fluid-type(font-size, $screen-xs, $screen-md-max, 30px, 42px);
    color: #fff;
    text-shadow: 0 2px #000;
  }
}
.btn{
  &.btn-thermo, &.btn-xtreme{
    color: #fff;
    margin-bottom: 10px;
  }
  &.btn-xtreme{
    background-color: #a1bf44;
  }
  &.btn-thermo{
    background-color: #316a9e;
  }
}
.testimonials {
  h1 {
    margin-bottom: 10vw;
  }
  h3 {
    font-size: 36px;
    font-weight: 600;
    font-style: italic;
    margin-top: 0;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    font-style: italic;

  }
  .testimonial {
    border-left: 5px solid #b1b1b1;
    margin-bottom: 80px;
    padding-left: 20px;
  }
}

.h2 {
  font-size: 60px;
  font-weight: 600;

  @include sm-max-query {
    font-size: 10vw;
  }
}

.h3 {
  @include sm-max-query {
    font-size: 10vw;
  }
}

.mv {
  @include fluid-type(margin-top, $screen-xs, $screen-md-max, 40px, 80px);
  @include fluid-type(margin-bottom, $screen-xs, $screen-md-max, 30px, 60px);
}

.font-24 {
  font-size: 24px;
  font-weight: normal;
  p {
    margin-bottom: 3vw;
  }
  h3, .blue-text {
    font-size: 34px;
    font-family: $font-family-sans-serif;
  }
  span.blue-text {
    font-size: inherit !important;
  }
}

.blue-text {
  color: #199ed9;
}

.font-20 {
  font-size: 20px;
}

.the_secret {
  .secret-title{
    .h3{
      color: red;
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 30px, 60px);
      line-height: 1;
      font-weight: bold;
      font-style: italic;
    }
    .h2{
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 30px, 55px);
    }
  }
  .bg-blue {
    p {
      margin-bottom: 2vw;
    }
    h2 {
      font-size: 74px;
      font-family: $secFont;
      font-style: italic;
      line-height: 0.9;
      margin-bottom: 3vw;
    }
    .bigger {
      font-size: 20px;
      font-weight: normal;
    }
  }
  .learn-how-body {
    .h3 {
      font-size: 38px;
      line-height: 0.8;
      margin-top: 5px;
    }
    .think {
      margin-top: 10vw;
    }
    .video {
      margin-bottom: 2vw;
      margin-top: 2vw;
    }
  }
  .pies {
    padding-top: 40px;
    padding-bottom: 40px;
    img {
      margin-top: 30px;
    }
  }
}

#playVideo {
  display: inline-block;
  font-size: 38px;
  line-height: 38px;
  text-transform: uppercase;
  background: #7A0000;
  @include background-image(linear-gradient(to bottom, #D20000, #7A0000));
  border-radius: 10px;
  padding: 8px 30px 10px;
  border: 1px solid #ff0000;
  margin: 10px auto 40px;

  color: #fff;
  text-decoration: none;

  &:hover {
    background: #7A0000;
  }
  @include sm-max-query {
    margin-bottom: 30px;

  }
}

.video-player {
  position: relative;
  &.play, &.pause {
    &:after {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      font-family: FontAwesome;
      content: " ";
      position: absolute;
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 20px, 40px);
      @include fluid-type(height, $screen-xs, $screen-md-max, 30px, 60px);
      @include fluid-type(width, $screen-xs, $screen-md-max, 50px, 100px);
      border-radius: 5px;
      color: #fff;
      vertical-align: baseline;
      @include fluid-type(line-height, $screen-xs, $screen-md-max, 30px, 60px);
      background-color: red;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1000;
      transition: 0.3s;
      opacity: 0.9;
      cursor: pointer;
    }
  }
  &.play:after {
    content: $fa-var-play;

  }
  &.learn-more{
    &.play:after{
      font-family: $headings-font-family;
      content: "Click Here to learn more about XR30";
      width: auto;
    }
  }
  &.pause:after {
    content: $fa-var-pause;
  }

  &:hover {
    &.play:after {
      opacity: 1;
      text-shadow: 0 0 30px darken(red, 20%);
      box-shadow: 0 0 1px darken(#ff0000, 10%), 0 0 2px lighten(#ff0000, 20%);
    }
    &.pause:after {
      opacity: 0.9;
      background: rgba(0, 0, 0, 0.8);

      text-shadow: 0 0 30px darken(red, 20%);
      box-shadow: 0 0 0 2px #555, 0 0 5px #000;
    }
  }

}

#home {
  h1, .h1 {
    text-align: center;
    line-height: 1;
    .black {
      font-weight: 600;
      font-style: italic;
      font-size: 97px;
      color: #101010;
      display: block;

      img {
        display: inline-block;
        height: 72px;
        vertical-align: baseline;
      }
    }
    .red {
      color: #e30000;
    }
    .grey {
      display: block;
      font-family: $secFont;
      font-size: 62px;
      color: #909090;

    }
  }

  .lose-fat {
    .h1 {
      padding-top: 10px;
      padding-bottom: 15px;
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 40px, 60px);
      font-style: italic;
      font-weight: 600;
      @include bounceIn();

      .blue {
        font-family: 'Sui Generis Rg', $headings-font-family;
        font-weight: 400;
        font-style: normal;
        display: inline-block;
        @include bounceIn($delay: 0.7s);
        @include fluid-type(font-size, $screen-xs, $screen-md-max, 30px, 44px);
        text-transform: capitalize;

      }
      img {
        @include bounceIn($delay: 1s);

      }
    }
  }
  .after-video {
    text-align: center;
    @include fluid-type(margin-top, $screen-xs, $screen-md-max, 20px, 40px);
    @include fluid-type(margin-bottom, $screen-xs, $screen-md-max, 20px, 60px);

    .h3 {
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 20px, 64px);
      font-weight: 600;
      font-style: italic;
      &.huge-one {
        @include fluid-type(font-size, $screen-xs, $screen-md-max, 20px, 84px);
        line-height: 1;
        margin-bottom: 0;
      }
    }

    .red {
      color: #e30000;
      font-family: 'Sui Generis Rg', $headings-font-family;
      margin-bottom: 35px;
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 20px, 50px);
    }
    .highlight {
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 22px, 40px);
      line-height: 1.2;
      color: #101010;
      padding-top: 20px;
      padding-bottom: 30px;

    }

  }

  .blue-banner{
    background: #2791d4;
    color: #fff;
    text-align: center;
    .h3{
      font-family: $headings-font-family;
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 35px, 60px);
      font-style: italic;
      font-weight: 600;
    }
  }
  .total-body {
    .h1 {
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 40px, 70px);
      font-style: italic;
      font-weight: 600;

      &.animate {
        @for $i from 1 through 5 {
          span:nth-child(#{$i}) {
            display: inline-block;
            @include bounceIn(
                    $delay: calc((0.5s * #{$i}))
            );
          }
        }
      }
    }

    .blue{
      font-style: normal;
      font-family: 'Sui Generis Rg', $headings-font-family;
    }

  }
  .blue {
    text-align: center;
    @include fluid-type(font-size, $screen-xs, $screen-md-max, 18px, 30px);
    color: #2791d4;

    &.animate {
      b {
        display: inline-block;
        @include pulse($delay: 4s);
      }
    }

  }

  .banner-top {
    @include background-image(linear-gradient(to bottom, #e7e7e7, #f5f5f5));
    border-top: 4px solid #cecdcd;

    padding: 10px 35px 30px;
    @include fluid-type(padding-left, $screen-xs, $screen-md-max, 15px, 50px);
    @include fluid-type(padding-right, $screen-xs, $screen-md-max, 15px, 50px);

    .h2 {
      line-height: 0.9;
      margin-bottom: 30px;
    }
    .btn-red {
      display: inline-block;
      width: auto;
      @include fluid-type(padding-left, $screen-xs, $screen-md-max, 15px, 120px);
      @include fluid-type(padding-right, $screen-xs, $screen-md-max, 15px, 120px);
    }
    .after-btn {
      font-size: 32px;
      font-family: $secFont;
      color: #a6a5a5;
      font-style: italic;
    }
  }

  .gives-all {
    .body {
      display: flex;
      justify-items: stretch;
      .dots {
        width: 57%;
        &.animate {
          ul {
            @for $i from 1 through 5 {
              li:nth-child(#{$i}) {
                span {
                  display: inline-block;
                  @include fadeInRight(
                          $delay: calc((0.5s * #{$i}))
                  );
                }

              }
            }

          }
        }
        ul {
          list-style: none;
          padding-left: 0;

          li {
            @include fluid-type(font-size, $screen-xs, $screen-md-max, 22px, 34px);
            font-family: $headings-font-family;
            font-weight: 600;
            line-height: 1;
            padding: 13px 30px 14px;
            transition: all 300ms;
            &:hover {
              transform: scale(1.1);
              box-shadow: 0 6px 6px -6px #222, 0 -6px 6px -6px #222,;
            }

            &:nth-child(odd) {
              background-color: #ffd800;
            }
            &:nth-child(even) {
              background-color: #2791d4;
              color: #fff;
            }

            &:before {
              content: "•";
              margin-right: 20px;
              margin-left: 20px;
            }

          }
        }
      }
      .img {
        width: 43%;
      }

      @include sm-max-query {
        display: block;
        .dots {
          width: 100%;
          ul {
            li {

              text-align: center;
              &:before {
                content: "";
                margin: 0;
              }
            }
          }
        }
        .img {
          width: 100%;
        }
      }
    }

  }

  .what-you-get {
    font-family: $secFont;
    font-style: italic;
    font-size: 28px;
    .row {
      font-family: Helvetica, Arial, sans-serif;
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 16px, 22px);
      font-style: normal;
      display: flex;
      align-items: center;
      height: 100%;
      border-bottom: 1px solid #cbcbcb;
      line-height: 1.4;
      padding: 10px 5px 10px 8px;
      @include fluid-type(padding-left, $screen-xs, $screen-md-max, 0px, 10px);

      &:first-of-type {
        border-top: 1px solid #cbcbcb;
      }
      &:nth-child(odd) {
        background-color: #f0f0f0;
      }
    }
  }

  .in-30-days {
    table {
      width: 100%;
      td {
        border: 2px solid #cbcbcb;
        width: 50%;
        text-align: center;
        @include fluid-type(font-size, $screen-xs, $screen-md-max, 18px, 30px);
        font-family: $secFont;
        padding: 15px 0;
        cursor: pointer;
        transition: all 200ms;
        &:hover {
          background-color: #ddd;
        }
      }
    }
  }

  .stopwatch, .placeholder, .after-video {

    background: url("../img/bg-grey.jpg") top center no-repeat;
    background-size: cover;
    padding: 40px 0;
  }

  .make-diff {
    text-align: center;

    .sec-header {
      font-family: $headings-font-family;
      font-size: 58px;
      line-height: 0.9;
      font-style: italic;
      font-weight: 600;
      margin-bottom: 15px;
      color: #333;
    }
    .y-bg,
    .bl-bg {
      font-family: $headings-font-family;
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 15px;
      line-height: 1;
      padding: 30px 60px;
    }

    .h2 {
      display: inline-block;
      position: relative;
      font-size: 68px;
      @include fluid-type(margin-bottom, $screen-xs, $screen-md-max, 25px, 50px);
      margin-top: 30px;
      text-align: center;
      width: 100%;
    }
    .h2::after {
      content: "";
      position: relative;
      display: block;
      margin: 20px auto auto;
      top: auto;
      right: auto;
      background: url("../img/stopwatch-copy.png") no-repeat;
      height: 150px;
      width: 150px;
      @include md-max-query {
        display: none;
      }
    }
    .h3 {
      font-size: 72px;
      font-family: $secFont;
      @include fluid-type(margin-bottom, $screen-xs, $screen-md-max, 30px, 60px);

    }
  }

  .key-benefits {
    .h2 {
      font-size: 63px;
      font-family: $secFont;
      font-style: italic;

      &.highlight {
        text-align: center;
        color: #fff;
        background: #454545;
        padding: 15px 0;
      }
    }

    .flex-container {
      padding: 40px 30px;
      .row {
        display: flex;
        flex-direction: row;
        align-content: center;

      }

      .left-side, .right-side {
        border-top: 2px solid #c0c0c0;
        width: 50%;
        padding-bottom: 15px;

        .title {
          padding: 0 20px;
          margin-top: -18px;
          font-family: $headings-font-family;
          font-size: 29px;
          font-weight: 600;
          margin-bottom: 15px;
        }
      }
      .last-row {
        .right-side {
          border-right: none;
          border-left: none;
        }
      }
      .left-side {
        .title {
          text-align: right;
          position: relative;
          &:before {
            position: absolute;
            left: -25px;
            display: block;
            content: "";
            background: #fff;
            width: 50px;
            height: 100%;
            z-index: 100;
            transform: skew(45deg)
          }
        }
        border-right: 1px solid #c0c0c0;
        padding-right: 40px;
      }
      .right-side {

        border-left: 1px solid #c0c0c0;
        padding-left: 40px;
        .title {
          position: relative;
          &:before {
            position: absolute;

            right: -25px;
            display: block;
            content: "";
            background: #fff;
            width: 50px;
            height: 100%;
            z-index: 100;
            transform: skew(-45deg)
          }
        }

      }
      .grey-bg {
        background-color: #454545;
        color: #dcdcdc;
      }
      @include sm-max-query {
        .row {
          display: block;
        }
        .left-side, .right-side {
          width: 100%;
          padding: 20px;
          text-align: center;
          border-width: 4px;
          .title {
            margin-top: -40px;
            text-align: center;
            &:before {
              display: none;
            }

          }
        }
      }
    }
  }
  .make-diff, .key-benefits, .epoc-effect {
    .h3, .h2 {
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 35px, 60px);
    }
  }
  @include sm-max-query {
    h1 {
      .black {
        font-size: 18vw;
      }
      img {
        display: inline-block;
        max-width: 100%;
        height: auto;
      }
      .grey {
        font-size: 10vw;
      }
    }
    .make-diff, .key-benefits, .epoc-effect {

      .sec-header, .y-bg, .bl-bg {
        font-size: 10vw;
      }
      .y-bg, .bl-bg {
        padding: 20px;
        text-align: center;

      }
    }
  }
}

.epoc-effect {
  background: url("../img/bg-grey.jpg") top center no-repeat;
  background-size: cover;
  font-size: 20px;
  padding-top: 40px;
  .h3 {

    font-size: 63px;
    font-family: $secFont;
    font-style: italic;
  }
  img {
    margin-top: 7%;
  }
}

.btn-red {
  font-family: $secFont;
  line-height: 0.8;
  padding: 10px 40px;
  display: inline-block;
  width: 100%;
  margin: 30px 0;
  background-color: #eb0303;
  @include background-image(linear-gradient(to top, #be0000, #eb0202));
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 0 0 1px #b10303, 0 2px 2px #000;
  color: #fff;
  font-size: 68px;
  text-shadow: 1px 0 0 #5f0000;
  border-radius: 10px;
  transition: all 0.5s;
  white-space: normal;
  &:link, &:visited, &:hover, &:active {
    color: #fff;
    text-decoration: none;
  }
  &:hover {
    background: #be0000;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 0 0 1px #b10303, 0 2px 10px #000;
    text-decoration: none;

  }

  @include sm-max-query {
    font-size: 12vw;
  }

}

.meet-t {
  h2 {
    font-size: 55px;
    font-weight: 600;

    span {
      font-size: 65px;
      display: block;
      font-style: italic;
      color: #e30000;
    }
  }
  @include sm-max-query {
    h2 {
      font-size: 10vw;
      span {
        font-size: 15vw;
      }
    }
  }
}

.double_hr {
  border-top: 1px solid #181818;
  border-bottom: 1px solid #181818;
  padding: 5px;
  margin-top: 4vw;
  margin-bottom: 4vw;
}

@keyframes inset-shine {
  0% {
    box-shadow: 0;
  }
  35% {
    box-shadow: inset 0 0 0 rgba(255, 255, 255, 1);

  }
  50%, 100% {
    box-shadow: inset 200px 0 5px rgba(255, 255, 255, 0);

  }
}

.hhl-before {
  position: relative;
  font-size: 32px;
  margin-bottom: 40px;
  margin-top: 40px;
  font-weight: normal;

  b {
    text-shadow: 1px 0 #101010;
    margin-right: 3px;
    color: #101010;
    font-weight: 900;
  }
  span {
    font-size: 54px;
    display: block;
    font-weight: bold;
  }
  &::before {
    /*content: '';*/
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-100%) translate(-50%);
    font-size: 58px;
    width: 100%;
    font-weight: bold;
  }
  &.life-before {
    margin-top: 50px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 700px;
    &::before {
      content: '';
    }
  }
}

.fresh-bulletin {

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin-bottom: 30px;
      font-weight: 500;
      font-size: 16px;
      text-align: left;

      span {
        color: #199ed9;
        font-family: 'Rift', sans-serif;
        font-weight: bold;
        font-size: 24px;
      }
      a {
        font-weight: bold;
        display: block;
        width: 100%;
        padding: 5px 15px;
        font-family: 'Rift', sans-serif;
        font-style: italic;
        background: rgba(0, 0, 0, .1);
        color: #101010;
        margin-top: 20px;
        text-align: right;
      }
      ul {
        margin-top: 10px;
        list-style-type: circle;
        padding-left: 20px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.scrollable {
  position: relative;
  transition: top .5s;
  -webkit-transition: top .5s;
  -moz-transition: top .5s;
}

.flex-wrapper {
  margin: 50px 0;
}

.flex-wrapper .wrapper-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.flex-wrapper {
  .wrapper-inner {
    .anchor-wrapper, .image-wrapper {
      width: 50%;
    }
  }

}

,

.pie-titles {
  text-align: center;
  span {
    display: block;
  }
  .big-title {
    margin-top: -20px;
    margin-bottom: 10px;
    font-size: 40px;
  }
  .sub-title {
    margin-bottom: 30px;
    font-size: 20px;
  }
}

.faq-singles {
  margin-top: 60px;
  .faq {
    border-bottom: 1px solid #101010;
    margin-bottom: 50px;
    h3 {
      font-family: 'Ubuntu', sans-serif;
      font-size: 42px;
      margin-bottom: 30px;
      margin-top: 0;
      text-transform: uppercase;
    }
    p {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 30px;
    }
  }
}

@include md-max-query {
  .flex-wrapper .wrapper-inner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .flex-wrapper .wrapper-inner .anchor-wrapper,
  .flex-wrapper .wrapper-inner .image-wrapper {
    width: 100%;
  }
  .think-life-wrapper .anchor-wrapper,
  .smart-wrapper .image-wrapper {
    margin-bottom: 50px;
  }
}
