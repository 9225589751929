$crumb-width : 300px;
$crumb-color: #fff;

.crumb-container{
  display: flex;
  flex-direction: row;
  flex: 1;
  color: $crumb-color;

  .crumb{
    display: inline-block;
    position: relative;
    padding: 10px 50px;
    flex: 1;
    max-width: 33%;

    &::after, &::before{
      content: '';
      position: absolute;
      width: 50px;
      height: 50%;
    }

    &::after {
      bottom: 0;
      right: 0;
      transform: skew(-8deg) translateX(45%);
    }

    &::before {
      top: 0;
      right: 0;
      transform: skew(8deg) translateX(45%);
    }

    &:last-child{
      &::after, &::before{
        display: none;
      }
    }
  }
}


@media screen and (max-width: 800px){
  .crumb-container{
    flex-direction: column;
    align-items: center;
    .crumb{
      padding-top: 70px;
      padding-bottom: 50px;
      max-width: 100%;
      &:before, &:after{
        width: 50%;
        height: 70px;
      }
      &:after{
        right: 0;
        bottom: 0;
        transform: skewY(-10deg) translateY(45%);
      }
      &:before{
        top: auto;
        bottom: 0;
        left: 0;
        transform: skewY(10deg)  translateY(45%);
      }
    }
  }

}
.step1{
  z-index: 50;
  background: #199ed9;
  &::before, &::after {
    background: #199ed9;
  }
}
.step2{
  z-index: 40;
  background: #faa71d;
  &::before, &::after {
    background: #faa71d;
  }
}

.step3{
  z-index: 30;
  background: #1a1a1a;
  &::before, &::after {
    background: #1a1a1a;
  }
}