#upsell{
  .bolded-line{
    font-size: 45px;
    line-height: 1.1;
    font-weight: 600;
  }
  h2{
    font-size: 54px;
    font-family: $secFont;
  }
  .btn-red{
    display: inline-block;
    width: auto;
  }
  .offer{
    margin-bottom: 30px;
  }
  .form-select{
    text-align: center;
    text-align-last: center;
    font-family: $secFont;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    margin:0 auto;
    height: auto;
    width: auto;
    display: inline-block;

  }
}