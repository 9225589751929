.meet-trainers {
  h1 {
    font-family: $secFont;
    text-align: center;
    font-size: 60px;
    border-bottom: 3px solid #c0c0c0;
    border-top: 3px solid #c0c0c0;
    margin-bottom: 5vw;
    margin-top: 0;
  }
  .first-section {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 5vw;

  }
  .second-section {
    p {
      line-height: 1.6;
    }
  }
  h2 {
    font-family: $font-family-sans-serif;
    font-size: 42px;
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;

  }
  .blue {
    font-size: 22px;
    color: #199ed9;
    font-style: italic;
    font-weight: 400;
  }
}

.banner {
  margin-bottom: 5vw;
  margin-top: 5vw;
  font-weight: 400;
  .title {
    font-family: $secFont;
    background: #212121;
    color: #fff;
    text-align: center;
    font-size: 80px;
    line-height: 55px;
    padding: 15px 5px 10px;
    span {
      font-size: 60px;
    }

  }
  .body {
    background: #f8f8f8;
    color: #151515;
    font-size: 32px;
    text-align: center;
    border: 2px solid #212121;
    padding: 5px;
    .h {
      margin-top: -30px;
      font-family: $secFont;
      font-size: 55px;
    }
  }
  .btn-red {
    font-size: 42px;
    width: 100%;
    display: inline-block;
    border-radius: 0;
  }
  .secure {
    filter: grayscale(100%);
    transform: scale(0.8);
    margin-top: -20px;
    margin-bottom: 20px;
  }
  .nsecure {
    transform: scale(0.6);
    transform-origin: 95%;
  }
  @include sm-max-query {
    .title {
      font-size: 10vw;
      line-height: 10vw;
      span {
        font-size: 13vw;
      }
    }
    .body {
      padding: 20px 10px;
      .h {
        font-size: 11vw;
        line-height: 11vw;
        margin-top: 0;
      }
      font-size: 22px;
    }
    .nsecure {
      transform: none;
      max-width: 60%;

    }
  }
}