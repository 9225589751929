
/* This stylesheet generated by Transfonter (https://transfonter.org) on May 12, 2017 9:11 PM */
@font-face {
  font-family: 'SocialCircles';
  src: url('../fonts/social/SocialCircles.eot');
  src: url('../fonts/social/SocialCircles.woff2') format('woff2'),
  url('../fonts/social/SocialCircles.woff') format('woff'),
  url('../fonts/social/SocialCircles.ttf') format('truetype'),
  url('../fonts/social/SocialCircles.svg#SocialCircles') format('svg'),
  url('../fonts/social/SocialCircles.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Rift';
  src: url('../fonts/rift-font/Rift-BoldItalic.eot');
  src: url('../fonts/rift-font/Rift-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rift-font/Rift-BoldItalic.woff2') format('woff2'),
  url('../fonts/rift-font/Rift-BoldItalic.woff') format('woff'),
  url('../fonts/rift-font/Rift-BoldItalic.ttf') format('truetype'),
  url('../fonts/rift-font/Rift-BoldItalic.svg#Rift-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Rift';
  src: url('../fonts/rift-font/Rift-LightItalic.eot');
  src: url('../fonts/rift-font/Rift-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rift-font/Rift-LightItalic.woff2') format('woff2'),
  url('../fonts/rift-font/Rift-LightItalic.woff') format('woff'),
  url('../fonts/rift-font/Rift-LightItalic.ttf') format('truetype'),
  url('../fonts/rift-font/Rift-LightItalic.svg#Rift-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Rift';
  src: url('../fonts/rift-font/Rift-MediumItalic.eot');
  src: url('../fonts/rift-font/Rift-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rift-font/Rift-MediumItalic.woff2') format('woff2'),
  url('../fonts/rift-font/Rift-MediumItalic.woff') format('woff'),
  url('../fonts/rift-font/Rift-MediumItalic.ttf') format('truetype'),
  url('../fonts/rift-font/Rift-MediumItalic.svg#Rift-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Rift Demi';
  src: url('../fonts/rift-font/Rift-Demi.eot');
  src: url('../fonts/rift-font/Rift-Demi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rift-font/Rift-Demi.woff2') format('woff2'),
  url('../fonts/rift-font/Rift-Demi.woff') format('woff'),
  url('../fonts/rift-font/Rift-Demi.ttf') format('truetype'),
  url('../fonts/rift-font/Rift-Demi.svg#Rift-Demi') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Rift';
  src: url('../fonts/rift-font/Rift-Italic.eot');
  src: url('../fonts/rift-font/Rift-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rift-font/Rift-Italic.woff2') format('woff2'),
  url('../fonts/rift-font/Rift-Italic.woff') format('woff'),
  url('../fonts/rift-font/Rift-Italic.ttf') format('truetype'),
  url('../fonts/rift-font/Rift-Italic.svg#Rift-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Rift';
  src: url('../fonts/rift-font/Rift-Regular.eot');
  src: url('../fonts/rift-font/Rift-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rift-font/Rift-Regular.woff2') format('woff2'),
  url('../fonts/rift-font/Rift-Regular.woff') format('woff'),
  url('../fonts/rift-font/Rift-Regular.ttf') format('truetype'),
  url('../fonts/rift-font/Rift-Regular.svg#Rift-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rift Demi';
  src: url('../fonts/rift-font/Rift-DemiItalic.eot');
  src: url('../fonts/rift-font/Rift-DemiItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rift-font/Rift-DemiItalic.woff2') format('woff2'),
  url('../fonts/rift-font/Rift-DemiItalic.woff') format('woff'),
  url('../fonts/rift-font/Rift-DemiItalic.ttf') format('truetype'),
  url('../fonts/rift-font/Rift-DemiItalic.svg#Rift-DemiItalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Rift';
  src: url('../fonts/rift-font/Rift-Medium.eot');
  src: url('../fonts/rift-font/Rift-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rift-font/Rift-Medium.woff2') format('woff2'),
  url('../fonts/rift-font/Rift-Medium.woff') format('woff'),
  url('../fonts/rift-font/Rift-Medium.ttf') format('truetype'),
  url('../fonts/rift-font/Rift-Medium.svg#Rift-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rift';
  src: url('../fonts/rift-font/Rift-Bold.eot');
  src: url('../fonts/rift-font/Rift-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rift-font/Rift-Bold.woff2') format('woff2'),
  url('../fonts/rift-font/Rift-Bold.woff') format('woff'),
  url('../fonts/rift-font/Rift-Bold.ttf') format('truetype'),
  url('../fonts/rift-font/Rift-Bold.svg#Rift-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rift';
  src: url('../fonts/rift-font/Rift-Light.eot');
  src: url('../fonts/rift-font/Rift-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/rift-font/Rift-Light.woff2') format('woff2'),
  url('../fonts/rift-font/Rift-Light.woff') format('woff'),
  url('../fonts/rift-font/Rift-Light.ttf') format('truetype'),
  url('../fonts/rift-font/Rift-Light.svg#Rift-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Ubuntu;
  src: url("../fonts/ubuntu/Ubuntu-R.ttf") format("truetype");
}
@font-face {
  font-family: Ubuntu;
  src: url("../fonts/ubuntu/Ubuntu-RI.ttf") format("truetype");
  font-style: italic;
}
@font-face {
  font-family: Ubuntu-Medium;
  src: url("../fonts/ubuntu/Ubuntu-M.ttf") format("truetype");
;
}
@font-face {
  font-family: Ubuntu-Medium;
  src: url("../fonts/ubuntu/Ubuntu-MI.ttf") format("truetype");
  font-style: italic;
}
@font-face {
  font-family: Ubuntu-C;
  src: url("../fonts/ubuntu/Ubuntu-C.ttf") format("truetype");
}
@font-face {
  font-family: Ubuntu;
  src: url("../fonts/ubuntu/Ubuntu-B.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: Ubuntu;
  src: url("../fonts/ubuntu/Ubuntu-BI.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: Ubuntu;
  src: url("../fonts/ubuntu/Ubuntu-L.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: Ubuntu;
  src: url("../fonts/ubuntu/Ubuntu-LI.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
/* This stylesheet generated by Transfonter (https://transfonter.org) on September 2, 2017 6:02 PM */

@font-face {
  font-family: 'Sui Generis Rg';
  src: url('../fonts/SuiGenerisRg/SuiGenerisRg-Regular.eot');
  src: url('../fonts/SuiGenerisRg/SuiGenerisRg-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SuiGenerisRg/SuiGenerisRg-Regular.woff2') format('woff2'),
  url('../fonts/SuiGenerisRg/SuiGenerisRg-Regular.woff') format('woff'),
  url('../fonts/SuiGenerisRg/SuiGenerisRg-Regular.ttf') format('truetype'),
  url('../fonts/SuiGenerisRg/SuiGenerisRg-Regular.svg#SuiGenerisRg-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}



/*


.bold{
  font-weight: bold;
}
.italic{
  font-style: italic;
}
.italic-bold{
  font-weight: bold;
  font-style: italic;
}
.rift{
  font-family: Rift, sans-serif;
}
.rift-demi{
  font-family: Rift-Demi, sans-serif;
}
.rift-medium{
  font-family: Rift-Medium, sans-serif;
}
.riftSoft{
  font-family: RiftSoft, sans-serif;
}
.riftSoft-demi{
  font-family: RiftSoft-Demi, sans-serif;
}
.riftSoft-medium{
  font-family: RiftSoft-Medium, sans-serif;
}
.openSans{
  font-family: OpenSans, sans-serif;
}
.ubuntu-Medium{
   font-family: Ubuntu-Medium, sans-serif;
 }
.ubuntu-light{
  font-family: Ubuntu, sans-serif;
  font-weight: 100;
}
*/