.order {

  .btn-red {
    @include fluid-type(font-size, $screen-xs, $screen-md-max, 22px, 42px);
    display: inline-block;
    width: auto;
    border-radius: 0;
  }
  .large-bold {

    @include fluid-type(font-size, $screen-xs, $screen-md-max, 30px, 46px);
    font-weight: 600;
    line-height: 1;
    margin-top: 20px;
  }
  .large {
    @include fluid-type(font-size, $screen-xs, $screen-md-max, 22px, 40px);
    font-style: italic;
    font-weight: 300;
    text-align: center;
    margin-bottom: 50px;
  }
  .ybb {
    display: flex;
    align-content: stretch;
    flex-wrap: wrap;
    .top{
      @include background-image(linear-gradient('to bottom', rgba(0,0,0,0), rgba(0,0,0,0.3)));
      padding: 5px 20px;
      justify-self: baseline;
    }
    .content{
      padding: 20px 20px;
    }
    .yellow, .blue, .black, .green {
      padding: 40px 0px;
      font-size: 20px;
      font-weight: 400;
      img {
        margin-bottom: 20px;
      }
    }
    .yellow {
      background: #ffd800;
    }
    .blue {
      background: #199ed9;
      color: #fff;
    }
    .black {
      background: #2f2f2f;
      color: #fff;
    }
    .green {
      background: #00C400;
      color: #fff;
    }
    .grey {
      background: #f4f4f4;
      padding-top: 30px;
      p {
        @include fluid-type(font-size, $screen-xs, $screen-md-max, 25px, 46px);
        font-weight: 600;
        line-height: 1;
        margin: 20px 0 40px;
        .red {
          color: red;
        }
      }

      .total-retail {
        @include fluid-type(font-size, $screen-xs, $screen-md-max, 18px, 30px);
        font-style: italic;
      }
    }
  }

  .control-label {
    font-weight: 400;
  }

  .btn-forward {
    font-family: $secFont;
    font-size: 35px;
    width: 100%;
    margin: 30px 0 10px;
    border-radius: 25px;
    border: none;
    background: #3aa202;
    box-shadow: 0 0 0 5px #fff, 0 0 2px 6px #3aa202;
    color: #fff;
  }
  .in-less-than {

    .h2 {
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 25px, 53px);
      .red {
        color: red;
      }
      font-style: italic;
      @include fluid-type(margin-bottom, $screen-xs, $screen-md-max, 22px, 60px);
    }
    .large {
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 22px, 30px);
      font-style: italic;
      font-weight: 600;
      color: #454545;
    }
    .smaller{
      @include fluid-type(font-size, $screen-xs, $screen-md-max, 15px, 22px);
      strong{
        color: red;
      }
    }
    .with-stopwatch {

      background: url("../img/5min.png") no-repeat left top;
      @include sm-max-query{
        background: none;
      }
      @include fluid-type(padding-bottom, $screen-xs, $screen-md-max, 30px, 80px);
      .checks {
        display: flex;
        justify-content: center;
        @include fluid-type(padding-left, $screen-xs, $screen-md-max, 0, 8.3%);
        i {
          background: #199ed9;
          color: #fff;
        }
        .grey {
          @include md-min-query{
            height: 70px;
          }
          @include sm-max-query{
            padding-top: 15px;
            padding-bottom: 15px;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin-top: 10px;
          margin-bottom: 15px;
          @include fluid-type(font-size, $screen-xs, $screen-md-max, 22px, 27px);
          @include fluid-type(padding-right, $screen-xs, $screen-md-max, 40px, 80px);
          @include fluid-type(padding-left, $screen-xs, $screen-md-max, 20px, 80px);
          background: #f4f4f4;
          transform: skewX(-7deg);
          letter-spacing: -0.30px;
          @include fluid-type(line-height, $screen-xs, $screen-md-max, 18px, 24px);
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            background-color: #199ed9;
            font-family: $headings-font-family;
            color: #fff;
            height: 100%;
            font-weight: 600;
            line-height: normal;
            vertical-align: middle;
            @include fluid-type(padding-right, $screen-xs, $screen-md-max, 5px, 18px);
            @include fluid-type(padding-left, $screen-xs, $screen-md-max, 5px, 18px);

            @include fluid-type(font-size, $screen-xs, $screen-md-max, 22px, 55px);
          }
          &:after {
            content: "";
            background: url("../img/checkmark.png") center center no-repeat;
            height: 72px;
            width: 68px;
            position: absolute;
            right: -20px;
            top: -20px;
            @include sm-max-query{
              right: 0;
              transform: scale(0.5);
            }
          }
        }
        &:nth-of-type(1) {
          .grey {
            &:before {
              content: '1';
            }
          }
        }
        &:nth-of-type(2) {
          .grey {
            &:before {
              content: '2';
            }
          }
        }
        &:nth-of-type(3) {
          .grey {
            &:before {
              content: '3';
            }
          }
        }
      }
    }

  }
  .btn-back {
    border-radius: 25px;
    border: none;
    background: #aaa;
    box-shadow: 0 0 0 5px #fff, 0 0 2px 6px #aaa;
    color: #fff;
    margin: 10px 0;
  }
  legend {
    font-family: $secFont;
    text-align: center;
    font-size: 60px;
    line-height: 1;
    margin-bottom: 45px;
    padding-bottom: 15px;
    border-bottom: none;
    color: #101010;

    small {
      display: block;
      font-size: 20px;
    }
  }
  .info {
    border-bottom: 1px solid #101010;
    border-top: 1px solid #101010;
    padding-bottom: 40px;
    padding-top: 30px;
    margin-bottom: 35px;
    margin-top: 40px;
  }

  h2 {
    font-size: 46px;
    font-weight: 600;
    text-align: center;
    font-family: $font-family-sans-serif;
    text-transform: uppercase;
    margin-top: 40px;
    span {
      font-family: $headings-font-family;
      font-size: 58px;
      color: #199ed9;
      display: block;
    }
  }
  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.loading {
  overflow: hidden;
}

.loading:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5) url("../img/logo.png") no-repeat center center;
  z-index: 1000;

}

.loading:after {
  content: "";
  position: fixed;
  top: 45%;
  left: 35%;
  bottom: 45%;
  right: 35%;
  color: #fff;
  font-size: 25px;
  display: block;
  animation: zoomIn 3s linear infinite;
  background: red;
  width: 30%;
  height: 10%;
  box-shadow: 0 0 50px 20px red;
  border-radius: 50px;
}

