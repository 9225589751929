#calculator{
  /*  How to Customize Checkbox and Radio Inputs with Custom CSS
    Source: https://www.wufoo.com/2011/06/13/custom-radio-buttons-and-checkboxes/
    Hide the original radios and checkboxes
    (but still accessible)

    :not(#foo) > is a rule filter to block browsers
                 that don't support that selector from
                 applying rules they shouldn't

*/
  .controls {
    display: block;
    margin: 1em 0;
  }
  div:not(#foo) > input[type='radio'],
  div:not(#foo) > input[type='checkbox'] {
    /* Hide the input, but have it still be clickable */
    opacity: 0;
    float: left;
    width: 0px;
  }
  div:not(#foo) > input[type='radio'] + label,
  div:not(#foo) > input[type='checkbox'] + label {
    clear: none;
    /* Left padding makes room for image */
    padding: .5em;
    /* Make look clickable because they are */
    cursor: pointer;
    /*background: url(off.png) left center no-repeat;*/
    border: 1px solid #dadad5;
    background-color: #dcdcdc;
    color: #000000;
    font-weight: 300;
    width: 100%;
    font-size: 20px;
  }
  /*
      Change from unchecked to checked graphic
  */
  div:not(#foo) > input[type='radio']:checked + label,
  div:not(#foo) > input[type='checkbox']:checked + label {
    background: #787d84;
    box-shadow: inset 2px 2px 1px #343930;
    color: #101010;
    font-weight: 900;
    text-shadow: 1px 2px 3px #ffffff;
    font-size: 20px;
  }
  div:not(#foo) > input[type='checkbox']:checked + label {
    /*background-image: url(check.png);*/
  }
  /* STOP HERE. The rest of this CSS is for style purposes only and is not needed for the demo to function.
                (but be sure to substitue out the LESS variables/mixins I used above)
     ---------------------------------------------------------------------------------------------------- */
  /* =VARIABLES (LESS @variables do not get compiled into the final CSS file)
     ---------------------------------------------------------------------------------------------------- */
  /*cream*/
  /*purple/grey*/
  /*mud*/
  /*lime*/
  /*grey*/
  /*grey*/
  /*grey*/
  /*orange*/
  /* =MIXINS
     ---------------------------------------------------------------------------------------------------- */
  /* =BASE STYLES
     ---------------------------------------------------------------------------------------------------- */
  html, body {
    color: #343a40;
    font-family: 'Ubuntu', 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 18px;
  }
  h1,
  h2 {
    text-align: center;
  }
  h1 {
    margin: 0 auto;
    top: 0;
    left: 0;
    color: #000000;
    font-weight: bold;
  }
  h3,
  h4,
  h5 {
    color: #4f4351;
  }
  small,
  hr {
    display: block;
    margin: 1em 0;
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  hr.dark {
    border-bottom: 1px solid #000000;
  }

  .control-label {
    color: #9a8864;
    font-size: 1.1em;
    font-weight: 600;
    margin: 1em 0;
  }
  .control-group {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    display: inline-block;
    overflow: hidden;
    padding: 1em;
    width: auto;
  }
  label.text {
    display: inline-block;
    margin-bottom: 0;
    font-size: 24px;
    width: 200px;
    font-weight: normal;
    font-family: 'Rift', 'Oswald', 'Ubuntu', 'Raleway', sans-serif;
    text-align: right;
    margin-right: 14px;
    line-height: 50px;
  }

  #resultmodal label.text {
    display: inline-block;
    margin-bottom: 0;
    font-size: 24px;
    width: 50%;
    font-weight: normal;
    font-family: 'Rift', 'Oswald', 'Ubuntu', 'Raleway', sans-serif;
    text-align: right;
    margin-right: 14px;
    line-height: 50px;
  }

  .calories-result, .fat-result, .carbs-result, .protein-result {
    display: inline-block;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: normal;
    font-family: 'Rift', 'Oswald', 'Ubuntu', 'Raleway', sans-serif;
    text-align: left;
    margin-right: 14px;
    line-height: 50px;
  }

  label.radio {
    text-align: center;
    font-family: 'Rift', 'Oswald', 'Ubuntu', 'Raleway', sans-serif;
  }

  input.textbox {
    border: 1px solid #dadad5;
    padding: 8px;
    font-size: 22px;
    font-weight: normal;
    border-radius: 5px;
    font-weight: 300;
    font-family: 'Ubuntu', 'Raleway', sans-serif;
  }

  input.textbox-inline, .exercise-level {
    border: 1px solid #dadad5;
    padding: 12px;
    font-size: 20px;
    font-weight: 300;
    font-family: 'Rift', 'Oswald', 'Ubuntu', 'Raleway', sans-serif;
    text-align: center;
    color: #000000;
    background-color: #dcdcdc;
    width: 100%;
    padding: .5em;
    text-transform: uppercase;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #000000;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #000000;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #000000;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #000000;
  }


  .result, .questions {

    border-radius: 5px;
    margin: 20px 0;
  }

  .result {
    padding: 10px;
  }
  .result label {
    font-size: 20px
  }

  .calories-result { color: #ff6f69 }
  .fat-result { color: #ff6f69; }
  .carbs-result { color: #ff6f69 }
  .protein-result { color: #ff6f69 }

  .required {
    border: 1px solid #FF0000 !important;
  }

  .text-center .header-lines {
    display: inline-block;
    padding-left: 1em;
    padding-right: 1em;
  }

  .h2 {
    font-size: 60px;
    font-weight: 600;
  }

  h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 25px;
    margin-bottom: 12.5px;
  }

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Rift",Oswald;
    font-weight: 400;
    line-height: 1.1;
    color: inherit;
  }

  .header-lines {
    border-top: 3px solid #c0c0c0;
    border-bottom: 3px solid #c0c0c0;
  }

  .font-24 {
    font-size: 24px;
    font-weight: normal;
  }

  .field-container{
    margin: 40px 0;
  }

  .label-description {
    font-size: 15px;
    color: #000000;
    line-height: 21px;
    font-family: 'Ubuntu', 'Raleway', sans-serif;
    width: 275px;
    display: block;
    margin-top: 6px;
  }

  .label-diet-description {
    font-size: 14px;
    line-height: 16px;
    display: block;
  }

  #calculate{
    font-weight: normal;
    font-family: 'Rift', 'Oswald', 'Ubuntu', 'Raleway', sans-serif;
    font-size: 24px;
    border-radius: 0;
    border:1px solid #df0909; padding: 10px 10px 10px 10px; text-decoration:none; display:inline-block;text-shadow: -1px -1px 0 rgba(0,0,0,0.3);font-weight:bold; color: #FFFFFF;
    background-color: #f62b2b; background-image: -webkit-gradient(linear, left top, left bottom, from(#f62b2b), to(#d20202));
    background-image: -webkit-linear-gradient(top, #f62b2b, #d20202);
    background-image: -moz-linear-gradient(top, #f62b2b, #d20202);
    background-image: -ms-linear-gradient(top, #f62b2b, #d20202);
    background-image: -o-linear-gradient(top, #f62b2b, #d20202);
    background-image: linear-gradient(to bottom, #f62b2b, #d20202);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#f62b2b, endColorstr=#d20202);
  }

  #calculate:hover{
    border:1px solid #b30808;
    background-color: #e40a0a; background-image: -webkit-gradient(linear, left top, left bottom, from(#e40a0a), to(#9f0202));
    background-image: -webkit-linear-gradient(top, #e40a0a, #9f0202);
    background-image: -moz-linear-gradient(top, #e40a0a, #9f0202);
    background-image: -ms-linear-gradient(top, #e40a0a, #9f0202);
    background-image: -o-linear-gradient(top, #e40a0a, #9f0202);
    background-image: linear-gradient(to bottom, #e40a0a, #9f0202);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#e40a0a, endColorstr=#9f0202);
    cursor: pointer;
  }
}